@import 'src/styles/_variables.sass'

.container
  &__top
    @apply flex flex-col md:flex-row items-center md:items-start md:justify-between md:flex-wrap xl:flex-nowrap
    padding: 74px 0 58px

    @screen md
      padding-bottom: 51px

  &__bottom
    @apply flex flex-col md:flex-row items-center md:items-start md:justify-between
    padding: 33px 0 40px

    &-text
      @apply text-sm leading-5 text-neutral-60 cursor-pointer
      &:hover
        @apply text-neutral-40

.logo
  width: 148px
  height: 67px
  @screen md
    margin-left: -20px

.input
  @apply text-neutral-100 bg-neutral-10
  width: 271px
  border-radius: 5px 0 0 5px
  outine: none
  padding: 11px 16px 13px
  &::placeholder
    @apply text-neutral-70

  @screen md
    width: 310px
    padding: 11px 20px 13px

.input-button
  @apply bg-primary text-white px-6
  border-radius: 0 5px 5px 0

.col-2
  @apply flex flex-col items-center w-full md:w-auto xl:mx-20 mb-16 md:mb-0
  min-width: max-content


.col-3
  @apply flex flex-col items-center md:items-start w-full
  @screen md
    width: 428px

.social__media 
  &:hover circle
   fill: $primary

.notification
  position: fixed
  top: 20px
  right: 20px

.success
  color: #008000

