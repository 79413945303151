//HowDoesItWorkSection
@import 'src/styles/mixins'
@import 'src/styles/_variables'

.section
  @apply relative text-black
  padding: 55px 0 55px

  @screen md
    padding: 101px 0 125px 0
    background-image: url('../../../../assets/media/home/plate-small.png'),  url('../../../../assets/media/home/plate-big.png'), linear-gradient(to right, $primary 60%, white 60% 100%)
    background-repeat: no-repeat, no-repeat, no-repeat
    background-position: 4% 0, 1% 300px, 0 0
    background-size: 26.5%, 34.6%

  @screen lg
    background-image: url('../../../../assets/media/home/plate-small.png'),  url('../../../../assets/media/home/plate-big.png'), linear-gradient(to right, $primary 120%, white)
    background-position: 18% 0, 17.9% 300px, 0 0

  @screen xl
    background-size: 382px, 499px 534px

.bg-image--mobile
  @apply absolute md:hidden
  top: 110px
  width: 267px
  height: 273px

.bg-color--mobile
  @apply md:hidden w-full h-64 mb-20

.title
  @apply font-bold text-neutral-100
  @include text(28px, 38px)
  margin-bottom: 39px

  @screen md
    @include text(46px, 56px)
