@import 'src/styles/mixins'

.card
  @apply flex flex-col items-center md:items-start w-full md:w-1/2 mb-5
  padding: 43px 35px 48px
  box-shadow: 1px 4px 30px rgba(129, 136, 172, 0.1)
  border-radius: 12px
  max-width: 500px

  @screen md
    box-shadow: none
    border-radius: 0
    max-width: none

  &__title
    @apply font-bold text-center md:text-left mb-4
    @include text(22px, 32px)

  &__text
    @apply text-neutral-70 text-center md:text-left
    font-size: 16px
