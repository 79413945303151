@import '~sass-rem'
@import 'src/styles/mixins'

*
  //word-spacing: -0.29em

.title
  @apply font-black text-primary text-center
  font-size: rem(22px)
  line-height: rem(22px)
  margin-bottom: 8px

  @screen lg
    @apply text-left
    font-size: rem(36px)
    line-height: rem(48px)
    margin-bottom: 25px

.subtitle
  @apply font-bold text-neutral-90 text-center lg:text-left
  font-size: rem(22px)
  line-height: rem(38px)
  margin-bottom: 8px

  @screen lg
    font-size: rem(32px)
    line-height: rem(38px)
    margin-bottom: 25px


.description
  @apply text-neutral-80 text-center lg:text-left
  font-size: rem(12px)
  line-height: rem(14px)
  margin-bottom: 18px

  @screen lg
    font-size: rem(18px)
    line-height: rem(30px)
    margin-bottom: 50px
    width: 90%

.buttonContainer
  display: flex
  width: 100%
  justify-content: space-between

  @screen lg
    justify-content: flex-start

.button
  @apply text-white font-bold
  padding: 10px 16px
  font-size: rem(14px)
  background-color: $secondary

  @screen lg
    font-size: rem(22px)
    line-height: rem(32px)
    padding: 24px 22px

  &:hover
    background-color: #e7ac93

.kickstarterbutton
  @apply text-white font-bold
  padding: 10px 16px
  font-size: rem(14px)

  @screen lg
    font-size: rem(22px)
    line-height: rem(32px)
    padding: 24px 22px
    margin-right: rem(22px)

.container-1
  @apply flex flex-col items-center lg:items-start order-2 lg:order-1 w-full lg:w-1/2

.container-2
  @apply relative order-1 lg:order-2 w-full lg:w-1/2 lg:pl-11 mb-14 lg:mb-0
  @include text(10px, 16px)
  margin-bottom: rem(2px)
  min-height: 310px

  @screen lg
    @include text(18px, 26px)
    margin-bottom: rem(4px)
    min-height: 520px

  @screen xl
    height: 520px

.card-base
  @apply absolute bg-white
  border-radius: 12px
  @screen lg
    padding: 30px 22px

.heart-card
  @apply flex justify-center items-center p-0
  left: 0
  top: 80px
  z-index: 10
  filter: drop-shadow(0px 25px 65px #D8DFE7)
  width: 50px
  height: 50px

  @screen lg
    top: -10px
    width: 85px
    height: 85px

  svg
    width: 22px
    height: 22px

    @screen lg
      width: 38px
      height: 38px


.meal-card
  @apply absolute flex flex-col items-center
  left: 8%
  top: 0
  width: 170px
  height: 192px
  background-color: #F9FAFB
  padding: 4px 15px 17px

  @screen lg
    left: 55px
    top: 44px
    width: 288px
    height: 325px
    padding: 7px 25px 30px

  &__title
    @apply font-bold
    @include text(10px, 15px)

    @screen lg
      @include text(18px, 26px)

.card-avatar
  width: 30px
  height: 30px

  @screen lg
    width: 50px
    height: 50px

  &--small
    width: 19px
    height: 19px
    @screen lg
      width: 32px
      height: 32px

.person-card
  @apply absolute flex items-center bg-white text-neutral-70
  @include text(11px, 15px)
  border-radius: 12px
  filter: drop-shadow(0px 32px 50.9078px #D8DFE7)
  padding: 14px 16px

  @screen lg
    padding: 18px 24px
    @include text(18px, 26px)

  &__text-small
    @apply text-neutral-90
    @include text(9px, 12px)

    @screen lg
      @include text(14px, 20px)

.person-card__1
  @apply hidden lg:flex
  top: -18px
  filter: drop-shadow(0px 32px 50.9078px #D8DFE7)
  @screen lg
    right: -30px
    max-width: 310px

  @screen xl
    right: -110px

.person-card__2
  @apply lg:hidden xl:flex
  left: -3%
  top: 66%
  max-width: 195px

  @screen sm
    left: 29%

  @screen lg
    top: 405px
    left: -22px
    max-width: 330px

.person-card__3
  @apply flex-col
  top: 30px
  left: 50%
  width: 160px
  height: 121px

  @screen lg
    top: 133px
    left: inherit
    right: -90px
    width: 272px
    height: max-content
    padding: 25px 26px 25px 30px

  @screen xl
    top: 133px
    right: -25px

.person-card__4
  @apply absolute flex items-center text-neutral-100
  @include text(9px, 12px)
  bottom: 0
  left: 10%
  border-radius: 10px
  background-color: #F9FAFD
  padding: 8px 13px

  @screen lg
    @include text(15px, 21px)
    bottom: 10px
    left: 38px
    max-width: 226px
    padding: 14px 24px

.meal-target-card
  @apply absolute
  bottom: 20px
  right: -10%
  border-radius: 12px
  background-color: #F2F4F7
  max-width: 283px
  height: max-content
  padding: 18px

  @screen sm
    right: 5%

  @screen lg
    bottom: 0
    right: -23%
    padding: 30px

  @screen xl
    right: -105px

  &__icon
    width: 18px
    margin-right: 11px
    @screen lg
      width: 31px
      margin-right: 19px

  &__name
    @apply text-neutral-70
    @include text(10px, 15px)

    @screen lg
      @include text(18px, 26px)

  &__weight
    @apply font-bold text-neutral-100
    @include text(19px, 22px)
    margin-right: 12px

    @screen lg
      @apply font-bold
      @include text(32px, 38px)
      margin-right: 14px

  &__change
    @apply font-bold text-red-50
    @include text(10px, 16px)

    @screen lg
      @include text(18px, 26px)

  &__description
    @apply text-neutral-60
    @include text(10px, 15px)

    @screen lg
      @include text(16px, 24px)

.hero-img
  @screen lg
    transform: scale(1.25)
