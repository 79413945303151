@import 'src/styles/_variables'
@import '~sass-rem'

.about
    width: 100%
    margin: rem(40px) 0
    padding-bottom: rem(40px)
    text-align: center

    @media screen and ( max-width: 600px )
        padding-bottom: 0

    & .aboutUs
        font-size: rem(14px)
        line-height: rem(24px)
        letter-spacing: 0.2em
        color: $neutral-70
        text-transform: uppercase
        margin: rem(20px) 0

    & .title
        font-weight: bold
        font-size: rem(32px)
        line-height: rem(38px)
        text-align: center
        color: $neutral-90
        margin-bottom: rem(15px)

        span
            color: $primary
            display: block
            margin-top: rem(10px)

        @media screen and ( max-width: 600px )
            font-size: rem(22px)

            span
                margin-top: rem(5px)

    & .aboutImg
        width: 100%
        overflow: hidden
        display: flex
        justify-content: center

        @media screen and ( max-width: 600px )
            img
                max-width: 175%

    & .body
        font-size: rem(16px)
        line-height: rem(32px)
        text-align: center
        color: $neutral-90
        width: 80%
        margin: rem(40px) auto

        @media screen and ( max-width: 600px )
            width: 90%
