.backdrop
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 9999

    display: flex
    align-items: center
    justify-content: center

    background: rgba(34, 34, 34, 0.8)
    backdrop-filter: blur(15px)

.container
    min-width: 100%
    min-height: 100%
    
    display: flex
    align-items: center
    justify-content: center

.loaderBody
    position: relative
    width: 140px
    height: 140px
    

    display: flex
    align-items: center
    justify-content: center
    
    svg
        position: absolute
        width: 66px !important
        height: 36px !important

.loaderCircle
    width: 140px
    height: 140px

    border: 4px solid rgba(250, 250, 250, 0.3)
    border-top: 4px solid #e2ab32
    border-radius: 50%

    animation: spin 2s linear infinite

@keyframes spin
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg)
