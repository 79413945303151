@import '~sass-rem'
@import 'src/styles/variables'

.container
  box-shadow: 0px 0px 40px rgba(230, 45, 71, 0.03)
  border-radius: 10px
  background-color: white
  width: 100%
  overflow: hidden
  position: relative

  @media (min-width: $breakpoint-md)
    width: rem(336px)

  img
    width: 100% !important

.name
  font-weight: bold !important
  font-size: rem(18px) !important
  line-height: rem(26px) !important
  color: $neutral-100 !important
  height: 50px

.calories
  font-size: rem(18px) !important
  line-height: rem(26px) !important
  color: $neutral-60 !important
  font-weight: 500 !important

.image
  width: 100%
  height: 150px
  overflow: hidden
  background-size: 130%
  background-position: center
  background-repeat: no-repeat

  @media (min-width: $breakpoint-md)
    background-size: 100%
    background-size: cover
    background-size: center
    height: 250px

.imageFallback
  background-color: $neutral-10
  span
    width: fit-content
    display: block
    margin: 25% auto 0 auto
    color: $neutral-70
    font-weight: bold 
    font-size: rem(18px) 
    line-height: rem(26px)
    @media (min-width: $breakpoint-md)
         margin: 35% auto 0 auto


.typeLabel
  height: 32px
  color: white
  padding: rem(6px) rem(16px)
  position: absolute
  right: 0
  top: 30px
  font-size: 14px
  line-height: 20px
  border-radius: 4px 0px 0px 4px

  @media (min-width: $breakpoint-md)
    border-radius: 0

.content
  padding: rem(20px)

  @media (min-width: $breakpoint-md)
    padding: rem(30px) rem(20px) rem(20px) rem(20px)

.body
  @screen md
    height: 70px

.buttonSecondary
  color: $secondary
  padding: rem(8px)
  font-size: rem(16px)
  line-height: rem(24px)
  font-weight: 500
  border-radius: 8px
  outline: none
  display: block
  border: 1px solid $secondary
  width: 100%

  @media (min-width: $breakpoint-md)
    width: max-content
    padding: rem(11px) rem(23px)

  &:hover
    background-color: $secondary
    color: white
    border-color: inherit

.footer
  border-top: 1px solid $neutral-30
  margin-top: rem(20px)
  display: flex
  justify-content: space-between
  padding-top: rem(20px)
  align-items: center

  @media (min-width: $breakpoint-md)

  span
    color: $primary

.price
  display: inline-block
  margin-right: 4px
  font-weight: bold
  font-size: rem(22px)
  line-height: rem(32px)

.mealInfoMobile
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: rem(15px)

.priceContainerMobile
  color: $primary
