$text-default: #333

// LR brand
$primary-black: #39393D
$primary-red: #EE223B
$primary-dark-blue: #40424D

// Greys
$white: #fff
$grey-1: #F8F8F8
$grey-2: #F3F3F3
$grey-3: #DFDFE1
$grey-4: #C4C4C4
$grey-5: #AFAFAF

$grey-light: #F1F4F7

// Reds
$red-10: #FFEBEB
$red-50: #F55456
$red-80: #D70004

// Blues
$blue-10: #EBF4FF
$blue-60: #2E81ED
$blue-70: #1268E4

// Greens
$green-80: #00D721

// EatRight
$primary: #90BF45
$secondary: #FF6B2C

// neutral
$neutral-10: #FAFAFA
$neutral-20: #F1F4F7
$neutral-30: #EDEDED
$neutral-40: #E0E0E0
$neutral-50: #C2C2C2
$neutral-60: #9E9E9E
$neutral-70: #757575
$neutral-80: #5E5E5E
$neutral-90: #404040
$neutral-100: #2E2F31

// Color representation
$green-food: #54EC51
$blue-workout: #4F6CDE
$cardio-orange: #FF8515

// Statuses
$error-red: #AB0000

// Tint
$red-tint: #FDF4F4

// Overlay
%overlay-dark
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(0, 0, 0, 0.4)

// breakpoints
$breakpoint-2xl: 1536px
$breakpoint-xl: 1280px
$breakpoint-lg: 1024px
$breakpoint-md: 768px
$breakpoint-sm: 640px

//Mobile footer
$mobileFooterHeight: 80px
