@import 'src/styles/mixins'
@import 'src/styles/_variables'
@import '~sass-rem'

.section
	padding: 78px 0 75px
	background-image: url('../../../../assets/media/home/dots-why-eat-right.png')
	background-repeat: no-repeat
	background-position: 0 77px
	background-size: 93px 86px

	@screen md
		background-position: 5.5% -20px
		background-size: 220px 200px
		padding: 134px 0 129px

.title
	font-weight: bold
	font-size: 28px
	line-height: 38px
	margin-bottom: 32px

	@media (min-width: $breakpoint-md)
		font-size: 32px

.meals
	box-shadow: 1px 4px 42px #EDF1F4

	@media (min-width: $breakpoint-md)
		width: rem(250px) !important

	button
		width: 100% !important

		@media (min-width: $breakpoint-md)
			padding: 0.6875rem !important

.mealCards
	width: 100%
	display: grid
	grid-template-columns: 1fr
	gap: 1rem
	margin-bottom: 3.5rem

	@media (min-width: $breakpoint-md)
		grid-template-columns: 1fr 1fr 1fr 1fr

.toOrderingButton
	color: $primary
	padding: rem(8px)
	font-size: rem(16px)
	line-height: rem(24px)
	font-weight: 500
	border-radius: 8px
	outline: none
	display: block
	border: 1px solid $primary
	width: 100%
	margin-top: 3.5rem

	@media (min-width: $breakpoint-md)
		padding: rem(11px) rem(23px)

	&:hover
		background-color: $primary
		color: white
		border-color: inherit
