@import 'src/styles/variables'

.footerWrapper
  position: fixed
  left: 0
  bottom: 0
  width: 100%
  height: 77px
  background-color: $white
  padding: 7px 17px 2px 17px
  z-index: 100

.navBox
  display: flex
  flex-direction: row
  justify-content: space-between

.navItem
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 11px 14px
  color: $neutral-70

  &--active
    color: $primary

.navIcon
  widows: 22px
  height: 22px
  margin-bottom: 8px

.navTitle
  font-weight: normal
  font-size: 12px
  line-height: 16px
