@import 'src/styles/variables'
@import '~sass-rem'

.link
  @apply relative text-base cursor-pointer mr-9 md:mb-3 lg:mb-0
  line-height: rem(24px)
  padding: 0 5px
  -webkit-font-smoothing: antialiased
  font-size: rem(16px)
  font-weight: 430
  line-height: rem(24px)
  
  &--active
    @apply text-primary
    transition: all .1s ease-in

    &::after
      @apply absolute left-0 block w-full bg-primary mb-2
      bottom: -13px
      content: ""
      height: 1px

.cart
  @apply relative self-center md:self-start lg:self-center cursor-pointer mr-5 md:mr-12
  &::after
    @screen lg
      @apply absolute top-0 block bg-neutral-30
      top: -7px
      right: -24px
      content: ""
      width: 1px
      height: 40px
  svg
    width: 24px

.sign-in-button
  @apply hidden md:block self-center leading-6
  flex-shrink: 0
  width: 100px
  padding-right: 22px
  padding-left: 22px

.mealsHeader
  background: url('../../../assets/media/mealsPage/meals-header-mobile.png')
  height: rem(265px)
  position: relative
  display: flex
  flex-direction: column

  @media (min-width: $breakpoint-md)
    background: url('../../../assets/media/mealsPage/meals-header-img.jpeg')
    background-repeat: no-repeat
    background-size: cover
    // height: rem(420px)
    height: rem(240px)

.mealsHeaderMenu
  z-index: 1
  color: #FFFFFF

.headerGradient
  height: 100%
  width: 100%
  position: absolute
  background: rgba(0, 0, 0, 0.6)
  display: flex
  flex-direction: column

  @media (min-width: $breakpoint-md)
    padding: 0 8.2%

.headerTitle
  color: #FFFFFF
  font-style: normal
  font-weight: 900
  display: flex
  align-items: flex-end
  font-size: rem(25px)
  line-height: rem(32px)
  margin-top: rem(121px) !important
  margin-left: rem(20px)

  @media (min-width: $breakpoint-md)
    font-size: rem(32px)
    line-height: rem(38px)
    margin-top: rem(80px) !important
    margin-left: rem(36px)

.headerText
  font-style: normal
  font-weight: normal
  display: flex
  align-items: flex-end
  color: #ffffff
  font-size: rem(16px)
  line-height: rem(20px)
  margin-top: rem(10px) !important
  margin-left: rem(20px)

  @media (min-width: $breakpoint-md)
    font-size: rem(24px)
    line-height: rem(30px)
    margin-top: rem(13px) !important
    margin-left: rem(36px)

.whiteLogo
  height: 51px
  width: 89px
  padding-top: 7px
  padding-bottom: 5px
  padding-right: 10px
  padding-left: 10px

.fullPlateIconWrapper
  position: relative
  svg
    width: 24px

.fullPlateIconDot
  position: absolute
  width: 10px
  height: 10px
  background-color: $secondary
  border-radius: 50%
  left: 19px
  bottom: 19px
