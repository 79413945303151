@import '~sass-rem'

.container
  @apply fixed top-0 z-20 h-screen bg-white p-8
  right: -299px
  width: 279px
  transition: all 0.5s ease
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
  overflow: auto

  &--active
    transform: translateX(-299px)

.close-btn
  @apply absolute top-2 -right-2

.link
  @apply relative text-base cursor-pointer mb-9
  line-height: rem(24px)

  &--active
    @apply font-medium text-primary
