@import 'src/styles/variables'
@import '~sass-rem'

.container
  height: fit-content
  flex-grow: 1
  padding: rem(30px) rem(20px)
  margin-bottom: 80px
  display: flex
  align-items: center

  @media (min-width: $breakpoint-md)
    padding: rem(44px) rem(37px)
    min-height: 100vh
    margin-bottom: 0

.contentTitle
    font-weight: bold
    font-size: 32px
    font-style: normal
    line-height: 38px
    color: #000000

.contentTypeContainer
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 0px
    margin: 0 auto

.contentBox
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 0px
    width: rem(335px)

    @media (min-width: $breakpoint-md)
      flex-direction: row
      width: rem(760px)
      
    @media (min-width: $breakpoint-lg)
        width: rem(1060px)

.contentTextBoxRight
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: rem(30px) rem(0px) rem(0px)
    margin: 0px
    width: rem(334px)

    h1
        font-weight: bold
        font-size: 22px
        line-height: 32px
        color: $neutral-100

    @media (min-width: $breakpoint-md)
      margin: rem(0px) rem(56px)
      width: rem(484px)
      height: rem(356px)

      h1
          font-size: 32px
          line-height: 38px

.contentTextBoxLeft
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: rem(30px) rem(0px) rem(0px)
    margin: 0px
    left: 0
    width: rem(334px)

    h1
        font-weight: bold
        font-size: 22px
        line-height: 32px
        color: $neutral-100

    
    @media (min-width: $breakpoint-md)
      margin: rem(0px) rem(56px)
      width: rem(484px)
      height: rem(356px)

      h1
          font-size: 32px
          line-height: 38px

.contentText
    flex: none
    order: 1
    align-self: stretch
    flex-grow: 0
    margin: 32px 0px

.contentImg    
    flex-grow: 0
    border-radius: 12px
    position: relative

.playButton
    width: rem(88px)
    height: rem(88px)
    display: flex
    align-items: center
    justify-content: center
    border: 3px solid rgba(255, 255, 255, 0.7)
    border-radius: rem(100px)
    box-sizing: border-box
    z-index: 1
    position: absolute
    top: 85px
    left: 120px

    @media (min-width: $breakpoint-md)
      top: 150px
      left: 200px

.video
    flex-grow: 0
    border-radius: 12px
    width: 100%
    height: 250px

    @media (min-width: $breakpoint-md)
      height: 380px
      width: 490px

.buttonPrimary
  background-color: $primary
  color: white
  padding: rem(16px) rem(36px)
  font-size: rem(16px)
  line-height: rem(24px)
  border-radius: 10px
  margin-top: rem(40px)
  outline: none
  display: block
  width: 100%
  &:hover
    background-color: #afc88a
    color: $white
    border-color: inherit

  @media (min-width: $breakpoint-md)
    width: auto
