@import 'src/styles/variables'
@import '~sass-rem'

.question
    margin-bottom: rem(38px)
    padding-bottom: rem(28px)
    border-bottom: 1px solid #EDEDED

    h3
        font-weight: bold
        font-size: rem(18px)
        line-height: rem(26px)

    @media (min-width: $breakpoint-md)

        h3
            font-weight: 700
            font-size: rem(22px)
            line-height: rem(32px)

.questionTitle
    display: flex
    align-content: flex-end
    align-items: center
    justify-content: space-between
    cursor: pointer
    margin-bottom: rem(10px)

.questionBody
    font-weight: normal
    font-size: rem(16px)
    line-height: rem(24px)
    display: flex
    align-items: center

    @media (min-width: $breakpoint-md)
        margin: 0 rem(40px)

.upArrow
    transform: rotate(180deg)
