@import 'src/styles/mixins'

.section
  @apply bg-grey-light
  padding: 74px 0 150px

  @screen md
    padding: 91px 0 175px

.title
  @apply text-neutral-100 font-bold text-center
  @include text(28px, 38px)
  margin-bottom: 47px

  @screen md
    @include text(46px, 56px)
    margin-bottom: 57px

.slide
  @apply flex-col items-center text-center mx-auto
  display: flex !important
  max-width: 550px

  @screen lg
    max-width: 700px

  &__title
    @apply text-neutral-80
    @include text(16px, 24px)
    margin-bottom: 42px

  &__avatar
    width: 56px
    height: 56px
    margin-bottom: 21px
    border-radius: 50%
    overflow: hidden

  &__name
    @apply block font-bold text-neutral-80 mb-1
    @include text(18px, 26px)

  &__company
    @apply text-neutral-60
    @include text(14px, 20px)
