@import '~sass-rem'
@import 'src/styles/variables'

.button
  height: auto
  padding: 7px 27px 9px
  font-weight: 600
  font-size: rem(16px)
  line-height: 140%
  border-radius: 11px

.primary
  border-radius: 11px
  background: $primary
  box-shadow: 0px 4px 8px 1px rgba(242, 96, 96, 0.15)
  color: $white
  padding: 7px 27px 9px

  &:hover,
  &:focus
    background-color: #afc88a
    color: $white
    border-color: inherit

.primary.disabled
  background-color: #f3f3f3
  color: $grey-3
  pointer-events: none

.secondary
  background: $white
  border: 1px solid $primary-dark-blue
  border-radius: 11px
  color: $primary-dark-blue

  &:hover,
  &:focus
    border: 1px solid rgba(0, 0, 0, 0.5)
    color: rgba(0, 0, 0, 0.5)

  &.disabled
    border: 1px solid $neutral-50
    color: $neutral-50
    pointer-events: none


.small-button
  box-shadow: 0px 4px 10px rgba(238, 34, 59, 0.15)
  border-radius: 3px
  background-color: #F42A43
  font-size: rem(14px)
  line-height: rem(19px)
  font-weight: 600
  color: $white
  padding: rem(8px) rem(28px)

  &.disabled
    color: $primary-dark-blue
    background-color: $white
