@import 'src/styles/variables'
@import '~sass-rem'

.container
    height: fit-content
    padding: rem(30px) rem(20px)
    background-color: $grey-light

    @media (min-width: $breakpoint-md)
        padding: rem(44px) rem(37px)
        margin-bottom: 0

.cardsContainer
    display: flex
    flex-direction: column
    justify-content: center
    margin-top: rem(50px)

    @media (min-width: $breakpoint-md)
        flex-direction: row

.testimonialCard
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    border-radius: 12px
    margin-right: rem(20px)
    background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 88.46%)
    height: 442px
    width: 100%
    @screen md
        width: calc(100% / 3)

.testimonialText
    width: rem(257px)
    height: rem(120px)
    margin: 61px 40px
    text-align: center
    font-weight: normal
    font-size: rem(16px)
    line-height: rem(24px)
    font-family: Circular Std

.testimonialName
    text-align: center
    z-index: 1
    margin-top: rem(38px)

    h4
        font-weight: bold
        font-size: rem(16px)
        line-height: rem(24px)
    p
        font-size: rem(14px)
        line-height: rem(20px)
        font-weight: normal

    @media (min-width: $breakpoint-md)
        h4
            font-weight: bold
            font-size: rem(18px)
            line-height: rem(26px)

.testimonialCardProfile
    width: rem(56px)
    border-radius: 100px

.quotesImage
    margin-top: rem(20px)
    position: absolute
    bottom: 0
    width: 100%
    display: flex
    justify-content: center
