@import 'src/styles/mixins'
@import 'src/styles/_variables'
@import '~sass-rem'

.section
  padding: 78px 0 0
  background-image: url('../../../../assets/media/home/dots-why-eat-right.png')
  background-repeat: no-repeat
  background-position: 0 77px
  background-size: 93px 86px

  @screen md
    background-position:  9.1% -54px
    background-size: 170px 156px
    padding: 134px 0 0

.logo
  @apply mx-auto
  height: 51px

.tableColFirst
  background-color: #fff
  border-radius: 0 12px 12px 0

.table
  @apply flex overflow-hidden md:overflow-visible text-neutral-100 text-center
  width: 100%

  @screen md
    width: auto

  &__col
    position: relative
    z-index: 2


    @apply flex flex-col
    max-width: 200px

    &--highlight
      @apply bg-neutral-100
      border-radius: 12px
      box-shadow: 0 6px 15px rgba(50, 36, 38, 0.35)

      .table__cell-text,
      .table__cell-text-sm,
      .table__cell-caption
        @apply text-neutral-10

    &--firs
      & .table__cell
        position: relative
        padding: 75px 33px 17px 20px
        width: min-content

        @screen md
          padding: 32px 40px

        .table__row-name
          @apply absolute
          top: 30px
          left: 15px
          transform: rotate(-90deg)

  &__cell
    @apply flex flex-col items-center justify-center
    width: 100%
    height: 108px
    padding: 25px 20px

    @screen md
      @apply w-full
      height: 130px
      padding: 15px 20px

    @screen lg
      height: 108px
      padding: 32px 40px

    &:not(:first-child)
      border-top: 1px dashed $neutral-50

    &:last-child
      height: 150px
      padding-bottom: 54px

  &__header-text
    @apply font-bold
    @include text(22px, 28px)

    @screen md
      @include text(22px, 32px)

  &__row-name
    @include text(18px, 18px, 700)

  &__cell-text
    @apply text-neutral-90 text-center
    @include text(18px, 26px)

  &__cell-text-sm
    @apply text-neutral-90 text-center
    @include text(16px, 24px)

  &__cell-caption
    @apply text-neutral-60

// Slider styles
.table
  padding-bottom: 113px
  position: relative
  @screen md
    position: initial
    padding-bottom: 129px

  :global(.slick-slide)
    width: 200px

  :global(.slick-dots)
    left: calc((100vw / 2) - 115px)
    width: max-content !important
    bottom: -56px

.slider
  display: flex
  overflow: visible
  max-width: 200px
  z-index: 1
  position: relative

.slide
  padding: 2rem 0
  margin: -2rem 0
  overflow: visible !important

.arrow
  width: 30px
  height: 30px
  position: absolute
  top: 37%
  transform: translateY(-50%)
  -webkit-transform: translateY(-50%)
  fill: #ddd
  cursor: pointer
  z-index: 200

.arrowLeft 
  left: 2%


.arrowRight 
  right: 2%


.arrowDisabled
  display: none
