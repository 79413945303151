@import 'src/styles/variables'
@import '~sass-rem'

.headerTitleBox
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 28px

//Headers
.header
  position: relative
  width: 100%
  background-color: $neutral-100
  display: flex
  flex-direction: column
  padding: 15px 20px 0 20px

.wizardPageHeader
  min-height: 150px
.planPageHeader
  min-height: 130px
  padding: 24px 20px
.plansPageHeader
  min-height: 140px
.mealsPageHeader
  min-height: 130px
.mealPageHeader
  min-height: 65px
.targetsPageHeader
  min-height: 70px
.dashboardPageHeader
  display: flex
  flex-direction: row
  gap: 15px
  min-height: 70px

//Title
.headerTitle
  color: $white
  font-weight: bold
  font-size: 18px
  line-height: 26px
.pageTitle
  color: $white
  font-weight: bold
  font-size: 22px
  line-height: 32px
.settingsPageHeaderTitleBox
  display: flex
  justify-content: space-between
  align-items: flex-start

//Content
.headerContentBox
  margin-bottom: 29px
.mealsPageHeaderContentBox
  margin-bottom: 20px
.plansPageHeaderContentBox
  display: flex
  justify-content: space-between
  align-items: center
.planPageHeaderContentBox
  display: flex
  justify-content: space-between
  align-items: center
.targetsPageHeaderContentBox
  display: flex
  justify-content: space-between
  align-items: center

//Icons
.notificationIcon
  width: 24px
  height: 24px
.logoIcon
  width: 54px
  height: 30px

//Buttons
.buttonPrimary
  height: rem(40px)
  background-color: $primary
  color: white
  padding: rem(9px) rem(20px)
  font-size: rem(16px)
  line-height: rem(24px)
  font-weight: 500
  border-radius: 8px
  outline: none
  display: block
  svg
    display: inline-block
    margin-right: rem(8px)
    height: 24px
    width: 24px
  &:hover
    background-color: #afc88a
    color: $white
    border-color: inherit

.goBackLink
  color: $white
  margin-bottom: rem(20px)
  svg
    display: inline-block
    margin: 0 rem(3px) rem(3px) 0

.goBackText
  font-size: rem(14px)
  margin-left: rem(17px)
