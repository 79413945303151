@import '~sass-rem'
@import 'src/styles/variables'

.container
  position: fixed
  top: 0
  left: 0
  z-index: 1000
  width: 100vw
  height: 100vh
  background-color: white
  overflow: auto
  overscroll-behavior: contain

  @screen md
    position: fixed
    top: 35px
    left: auto
    width: fit-content
    height: 90vh
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.25)
    padding: rem(55px) rem(43px) rem(35px) rem(43px)

  &--authenticated
    border-radius: 10px
    @screen md
      top: 40px
      left: 13.5%
      height: 90vh
      width: 73%

  h1
    font-weight: bold
    font-size: rem(22px)
    line-height: rem(32px)
    margin-bottom: rem(20px)

    @media (min-width: $breakpoint-md)
      font-size: rem(32px)
      line-height: rem(38px)
      margin-bottom: rem(36px)

  h2
    font-weight: bold
    font-size: rem(22px)
    line-height: rem(32px)
    color: $neutral-100
  h3
    color: $neutral-70

.backdrop
  position: absolute
  top: 0
  left: 0
  height: 100%
  width: 100%
  display: block
  z-index: 120

.body
  padding: rem(20px)
  padding-bottom: rem(90px)

.description
  max-width: 500px
  margin-bottom: 25px
  color: $neutral-70

.headerMobile
  width: 100%
  height: rem(65px)
  padding: rem(20px)
  color: $white
  background-color: $neutral-100

.closeButtonMobile
  display: flex
  align-items: center
  gap: rem(10px)
  span
    font-size: 14px
    line-height: 20px

.closeButton
  position: absolute
  right: 18px
  top: 12px
  outline: none

.mealSection
  display: flex
  justify-content: flex-between
  flex-direction: column
  gap: rem(10px)
  padding-bottom: rem(30px)
  border-bottom: 1px solid $neutral-40

  @media (min-width: $breakpoint-md)
    flex-direction: row
    gap: rem(54px)
    padding-bottom: rem(50px)

.mealImage
  position: relative
  width: 100vw
  height: 225px
  top: -26px
  left: -16px
  background-size: 150%
  background-position: center

  @media (min-width: $breakpoint-md)
    background-size: cover
    width: 340px
    height: 266px
    top: -20px
    left: -20px
    border-radius: 10px

.mealType
  position: absolute
  color: white
  padding: rem(6px) rem(16px)
  right: 0
  top: 34px
  border-radius: 4px 0px 0px 4px
  font-size: 14px
  line-height: 20px

.mealTag
  font-weight: 500
  font-size: rem(14px)
  line-height: rem(20px)
  background-color: #F1F7E7
  border-radius: 8px
  padding: rem(8px) rem(22px)
  margin-bottom: rem(17px)

  @media (min-width: $breakpoint-md)
    font-size: rem(16px)
    line-height: rem(24px)
    margin-bottom: rem(23px)

  span
    color: $secondary

.nutritionContainer
  display: flex
  justify-content: flex-start
  gap: rem(8px)

.nutritionContainerWrapper
  max-width: rem(550px)
  overflow: auto

/* Hide scrollbar for Chrome, Safari and Opera */
.nutritionContainerWrapper::-webkit-scrollbar
  display: none

/* Hide scrollbar for IE, Edge and Firefox */
.nutritionContainerWrapper
  -ms-overflow-style: none
  scrollbar-width: none

.macronutrient
  min-width: 102px
  height: 88px
  background-color: $grey-1
  border-radius: 12px
  padding: rem(15px)
  text-align: center
  font-size: rem(14px)
  line-height: rem(20px)

  @media (min-width: $breakpoint-md)
    min-width: 120px
    height: 100px
    margin-top: rem(12px)
    padding: rem(20px) rem(30px)
    font-size: rem(16px)
    line-height: rem(24px)

  span
    display: block
    margin: rem(13px) auto 0 auto
    font-weight: bold
    font-size: rem(16px)
    line-height: rem(24px)
    color: $neutral-90

    @media (min-width: $breakpoint-md)
      font-size: rem(18px)
      line-height: rem(26px)
      margin: rem(9px) auto 0 auto

.componentsSection
  position: relative
  padding: rem(30px) rem(20px) rem(60px) rem(20px)
  border-bottom: 1px solid $neutral-40

  @media (min-width: $breakpoint-md)
    padding: rem(46px) 0 rem(52px) 0

.componentsSectionTitle
  display: flex
  align-items: center
  gap: rem(12px)

  @media (min-width: $breakpoint-md)
    gap: rem(21px)
    justify-content: space-between

  h2
    font-weight: bold
    font-size: 18px
    line-height: 26px

    @media (min-width: $breakpoint-md)
      font-size: 22px
      line-height: 32px

.pastSelectionWrapper
  background-color: white

.pastSelectionLabel
  color: #2E2F31 !important

.addMoreDropdown
  position: absolute
  right: 0
  box-shadow: 0px 4px 12px rgba(139, 147, 156, 0.33)
  border-radius: 10px
  padding: rem(18px)
  z-index: 10
  background-color: white
  li
    padding: 0 rem(16px)
    cursor: pointer
    font-size: 16px
    line-height: 44px
  li:hover
    background-color: $grey-1
  img
    width: 44px
    height: 35px
    display: inline-block
    margin-right: rem(16px)

.componentsGrid
  display: flex
  flex-direction: column
  gap: rem(22px)
  margin-top: rem(30px)

  @media (min-width: $breakpoint-md)
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: rem(38px)
    justify-content: space-between
    margin-top: rem(42px)

.component
  display: flex
  justify-content: flex-start
  gap: rem(20px)

.addMoreButton
  outline: none
  display: flex
  justify-content: space-between
  align-items: center
  gap: rem(6px)
  color: $blue-70

.componentImage
  height: 93px
  width: 90px
  border-radius: 10px
  flex-shrink: 0
  background-size: cover
  background-position: center

  @media (min-width: $breakpoint-md)
    height: 116px
    width: 146px

.componentName
  display: block
  font-weight: bold
  font-size: rem(16px)
  line-height: rem(24px)
  color: $neutral-100
  margin-top: rem(3px)
  margin-bottom: rem(20px)

  @media (min-width: $breakpoint-md)
    margin-top: rem(8px)
    font-size: rem(22px)
    line-height: rem(32px)

.componentSelect
  width: calc(100vw - 169px)

  @media (min-width: $breakpoint-md)
    width: 294px

.truncate
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  display: block

.selectCurrency
  color: $primary
  display: inline-block
  margin-left: rem(5px)

.daysSection
  padding: rem(30px) rem(20px) rem(40px) rem(20px)
  border-bottom: 1px solid $neutral-40
  position: relative

  @media (min-width: $breakpoint-md)
    padding: rem(46px) 0 rem(52px) 0

.daysGrid
  display: flex
  flex-direction: column
  gap: rem(12px)
  margin-top: rem(20px)

  @media (min-width: $breakpoint-md)
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: rem(20px)
    justify-content: space-between
    margin-top: rem(42px)

.daysSectionNotification
  position: static
  min-width: 100%
  border-radius: 8px
  margin-top: rem(17px)

  @media (min-width: $breakpoint-md)
    position: absolute
    min-width: auto
    right: 0
    top: 40px
    border-radius: 8px

.dayContainer
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
  height: 100%
  border-radius: 10px
  background-color: $grey-1
  padding: rem(20px)

  @media (min-width: $breakpoint-md)
    padding: rem(30px) rem(40px)ё

  &Disabled
    opacity: 0.4
    pointer-events: none
  h2
    font-weight: bold
    font-size: 16px
    line-height: 24px
    color: $neutral-100

    @media (min-width: $breakpoint-md)
      font-size: 18px
      line-height: 26px

  h3
    color: $neutral-80
    font-size: 14px
    line-height: 24px

  input
    height: 40px
    width: 38px
    border-radius: 8px
    font-weight: bold
    font-size: 18px
    line-height: 26px
    outline: none
    text-align: center

    @media (min-width: $breakpoint-md)
      height: 42px
      width: 46px

    &:disabled
      background-color: white
      color: $neutral-50

  a
    color: $secondary
    display: block
    margin-bottom: rem(20px)
    font-size: 14px
    line-height: 20px

    @media (min-width: $breakpoint-md)
      margin-bottom: rem(16px)

  svg
    display: inline-block

.minusButton
  height: 42px
  width: 42px
  border-radius: 8px
  background-color: $neutral-30
  color: $neutral-60
  text-align: center
  outline: none

.plusButton
  height: 42px
  width: 42px
  border-radius: 8px
  color: white
  background-color: $primary
  text-align: center
  outline: none

  &:disabled
    background-color: #f3f3f3
    color: $grey-3

.macronutrientsSection
  padding: rem(30px) rem(20px) rem(84px) rem(20px)
  border-bottom: 1px solid $neutral-40

  @media (min-width: $breakpoint-md)
    padding: rem(46px) 0 rem(40px) 0

  h2
    font-weight: bold
    font-size: 18px
    line-height: 26px

    @media (min-width: $breakpoint-md)
      font-size: rem(22px)
      line-height: rem(32px)

  ul
    width: 100%
    display: flex
    justify-content: space-between
    margin-top: rem(20px)

.macronutrientsMode
  color: $neutral-70
  cursor: pointer
  padding: rem(17px) 0

  &Active
    font-weight: bold
    color: $secondary
    border-bottom: 2px solid $secondary

.macronutrientsContainerWrapper
  width: 100%
  overflow: auto

.macronutrientsContainer
  display: flex
  flex-direction: row
  gap: rem(4px)
  width: max-content
  margin-top: rem(30px)

  @media (min-width: $breakpoint-md)
    margin-top: rem(35px)

/* Hide scrollbar for Chrome, Safari and Opera */
.macronutrientsContainerWrapper::-webkit-scrollbar
  display: none

/* Hide scrollbar for IE, Edge and Firefox */
.macronutrientsContainerWrapper
  -ms-overflow-style: none
  scrollbar-width: none

.macronutrientContainer
  padding: rem(14px) rem(11px) rem(14px) rem(16px)
  color: white
  background-color: $neutral-100
  border-radius: 10px
  font-weight: bold
  font-size: rem(22px)
  line-height: rem(32px)
  height: 110px
  width: fit-content
  min-width: 121px

  h2
    font-weight: bold
    font-size: 14px
    line-height: 20px
    color: $neutral-40
  h3
    font-size: rem(12px)
    line-height: rem(16px)
    color: $neutral-60
    margin-top: rem(10px)
  svg
    display: inline-block

.macronutrientsFilterWrapper
  width: 100%
  overflow: auto

  ul
    display: flex
    gap: rem(28px)
    width: max-content

/* Hide scrollbar for Chrome, Safari and Opera */
.macronutrientsFilterWrapper::-webkit-scrollbar
  display: none

/* Hide scrollbar for IE, Edge and Firefox */
.macronutrientsFilterWrapper
  -ms-overflow-style: none
  scrollbar-width: none

.changeUp
  font-size: rem(14px)
  line-height: rem(20px)
  color: $red-50
  span
    position: relative
    right: 5px
    vertical-align: bottom

.changeDown
  font-size: rem(14px)
  line-height: rem(20px)
  color: $primary
  span
    position: relative
    right: 5px
    vertical-align: bottom

.targetsLink
  margin-top: rem(30px)
  font-size: rem(12px)
  line-height: rem(16px)
  text-align: center

  @media (min-width: $breakpoint-md)
    text-align: left

  a
    display: inline-block
    color: $secondary
    margin-left: rem(10px)

.totalCostSection
  padding: rem(30px) rem(20px) rem(35px) rem(20px)
  display: flex
  justify-content: space-between
  align-items: center
  gap: rem(42px)
  border-bottom: 1px solid $neutral-40

  @media (min-width: $breakpoint-md)
    padding: rem(46px) 0 rem(50px) 0
    justify-content: flex-start

  h2
    font-size: rem(18px)
    line-height: rem(26px)

    @media (min-width: $breakpoint-md)
      font-size: rem(22px)
      line-height: rem(32px)

  span
    font-weight: bold
    font-size: rem(22px)
    line-height: rem(32px)
    color: $primary

.footer
  padding-top: rem(30px)
  padding-bottom: rem(10px)
  padding-left: rem(20px)
  padding-right: rem(20px)

  @media (min-width: $breakpoint-md)
    padding-bottom: rem(20px)
    padding-left: 0
    padding-right: 0

.footerNotification
  margin-bottom: rem(22px)
  border-radius: 8px

.buttonPrimary
  background-color: $primary
  color: white
  padding: rem(11px) rem(26px)
  font-size: rem(16px)
  line-height: rem(24px)
  border-radius: 8px
  outline: none
  display: block
  &:hover
    background-color: #afc88a
    color: $white
    border-color: inherit

.buttonSecondary
  color: $secondary
  padding: rem(11px) rem(16px)
  font-size: rem(16px)
  line-height: rem(24px)
  outline: none
  display: block
  border: none

.bottomButtonContainer
  display: flex
  flex-direction: column-reverse
  gap: rem(22px)

  @media (min-width: $breakpoint-md)
    flex-direction: row
    justify-content: flex-end
