.loadingContainer
  position: fixed
  z-index: 9999
  top: 0
  left: 0
  bottom: 0
  right: 0
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(0,0,0,0.4)
