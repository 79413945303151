@import 'src/styles/mixins'
@import 'src/styles/variables'

.mark
  width: 18px
  height: 18px
  border-radius: 100%
  border: 1px solid $neutral-60
  margin-right: 10px

.button
  @apply flex items-center text-neutral-80 cursor-pointer
  @include text(14px, 20px)
  width: 140px
  height: 46px
  border: 1px solid $neutral-20
  border-radius: 10px
  padding: 0 16px

  @screen md
    width: 180px

  &--checked
    @apply text-neutral-100
    border-color: $primary

    .mark
      border: 5px solid $primary

.text
  word-break: break-all
  max-width: 78px
  @screen md
    max-width: none
