@import '~sass-rem'
@import 'src/styles/variables'

.container
  width: fit-content
  min-width: 345px
  border-radius: 12px
  overflow: hidden
  display: flex
  justify-content: space-between
  align-items: center
  padding: rem(12px) rem(16px)

  @media (min-width: $breakpoint-md)
    padding: rem(12px) rem(20px)

  button
    outline: none
  &Success
    border: 1px solid $primary
    background-color: #EAF3DB
  &Error
    background-color: #FFEEEE
    color: #EF1733

.icon
  display: inline-block
  margin-right: rem(14px)

.closeIcon
  height: 22px
  width: 22px
  color: $neutral-60
