@import 'src/styles/_variables'
@import 'src/styles/mixins'

.section
  @apply relative flex flex-col text-center bg-grey-light
  @screen md
    @apply justify-between text-left
    flex-direction: row !important
    justify-content: flex-start !important
    height: 750px

.content
  @apply w-full
  margin: 58px 0 55px
  @screen md
    width: fit-content
    margin-left: auto
    margin-right: rem(90px)

.title
  @apply font-bold text-black mb-5 md:mb-9
  @include text(28px, 38px)
  @screen md
    @include text(46px, 56px)

.description
  @apply text-neutral-80
  @include text(16px, 28px)
  margin-bottom: 55px
  @screen md
    @include text(16px, 30px)
    max-width: 342px
    margin-bottom: 100px

.hashtag
  @apply text-neutral-100 uppercase
  @include text(22px, 32px)

.imagesGrid
  @apply w-full
  display: grid
  place-items: stretch
  height: inherit
  height: 615px
  grid-template-columns: 50% 50%
  grid-template-rows: 33% 33% 34% 
  grid-template-areas: "image1 image1" "image1 image1" "image2 image3"

  @screen md
    flex: 1.1
    height: 100%
    grid-template-columns: 29% 29% 42%
    grid-template-rows: 31% 39% 30% 
    grid-template-areas: "image1 image1 image2" "image1 image1 image3" "image4 image5 image6"



