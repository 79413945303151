@import 'src/styles/variables'
@import 'src/styles/mixins'

.container
  @apply cursor-pointer
  border-radius: 8px

  &--authenticated
    @apply flex bg-neutral-10 p-3 pl-2

  &--unauthenticated
    @apply absolute z-10 hidden bg-white
    left: -81px
    bottom: -75px
    width: 138px
    min-height: 66px
    padding: 12px 16px 12px
    border: 1px solid $neutral-50

    &::before
      @apply bg-white
      position: absolute
      top: -6px
      right: 8px
      content: ''
      display: block
      width: 0
      height: 0
      border-top: 9px solid $neutral-50
      border-bottom: 9px solid transparent
      border-left: 9px solid transparent
      transform: rotate(-45deg)

.caption
  @apply text-neutral-60
  @include text(12px, 16px)

.city
  @apply text-neutral-100
  @include text(14px, 20px)
