.carousel
  :global(.slick-prev)::before,
  :global(.slick-next)::before
    display: none !important

  :global(.slick-dots)
    bottom: -75px

    li
      margin: 0

      button::before
        @apply text-neutral-50
        font-size: 10px

  :global(.slick-dots li.slick-active button)::before
    @apply text-primary
