@import 'src/styles/mixins'
@import 'src/styles/variables'

.container
  @apply fixed hidden bg-white
  top: calc((100% - 475px) / 2)
  left: calc((100% - 320px) / 2)
  z-index: 111
  width: 320px
  border-radius: 12px
  padding: 40px 15px 25px

  @screen md
    top: calc((100% - 367px) / 2)
    left: calc((100% - 677px) / 2)
    width: 677px
    height: 367px
    padding: 35px 40px 57px

.title
  @apply font-bold text-neutral-100
  @include text(22px, 32px)
  margin-bottom: 14px

.message
  @apply text-neutral-80
  @include text(16px, 24px)
  margin-bottom: 42px

.error
  @apply text-neutral-80
  @include text(14px, 24px)
  color: $red-80
  margin-top: -35px
  margin-bottom: 24px

.close
  @apply absolute cursor-pointer
  top: 10px
  right: 10px

  @screen md
    top: 35px
    right: 35px

.buttonGrp
  display: flex
  width: 100%
  justify-content: flex-end
  align-items: flex-end

.buttonPrimary
  background-color: $primary
  color: white
  padding: rem(11px) rem(40px)
  font-size: rem(16px)
  line-height: rem(24px)
  font-weight: 500
  border-radius: 8px
  outline: none
  display: block
  width: 100%

  @media (min-width: $breakpoint-md)
    width: fit-content

  &:hover
    background-color: #afc88a
    color: $white
    border-color: inherit

  &:disabled
    background-color: #f3f3f3
    color: $grey-3
    cursor: not-allowed

.buttonSecondary
  background-color: transparent
  color: $secondary
  padding: rem(11px) rem(40px)
  font-size: rem(16px)
  line-height: rem(24px)
  font-weight: 500
  border-radius: 8px
  outline: none
  display: block
  width: 100%
  margin-right: 10px
  border: 1px solid $secondary

  @media (min-width: $breakpoint-md)
    // margin-top: rem(44px)
    width: fit-content

  &:hover
    background-color: $secondary
    color: $white

.cancelButton
  outline: none
  display: block
  width: 100%
  margin-top: rem(15px)
  border: none
  color: $secondary

  @media (min-width: $breakpoint-md)
    margin-top: rem(22px)
    margin-right: 10px
