.sliderWrapper
  width: 100%

.center 
  width: 100%

.slick-initialized .slick-slide 
  transform: scale(0.9)
  transition: transform 100ms

.center .slick-center 
  transform: scale(1)
  transition: transform 500ms
