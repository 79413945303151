@import '~sass-rem'
@import 'variables'
@import 'src/assets/fonts/circular-font/style.css'
@import 'mixins'

\:root
  --brand-green: $primary

*
  -moz-osx-font-smoothing: grayscale
  font-feature-settings: "kern" off
  font-variant: none

body
  box-sizing: border-box
  margin: 0
  font-family: 'Circular Std', sans-serif
  color: #333
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale

code
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace

a:hover
  color: inherit

input:focus-visible
  outline: none

.auth-content-wrapper
  @apply bg-grey-light
  width: calc(100% - 210px) // 210px - width of sidebar

.auth-content-container
  @apply py-8 md:py-10 px-5 md:px-3 lg:px-9 mx-auto
  min-height: 100vh
  max-width: 1230px

/* common elements */
.common-section
  display: flex
  flex-direction: column
  align-items: center
  width: 100%

.common-container
  width: 100%
  margin: 0 auto
  padding-right: 20px
  padding-left: 20px

  @screen md
    //max-width: 640px\
    max-width: calc(#{$breakpoint-md} - 60px)
    padding-right: 0
    padding-left: 0
    //padding: 0 20px

  @screen lg
    //max-width: 768px
    max-width: calc(#{$breakpoint-lg} - 80px)

  @screen xl
    max-width: 1060px
    //padding: 0 30px

.content-container
  padding-top: 2rem
  padding-bottom: 2rem

  @screen md
    padding: 3rem 0


.section-heading
  @apply text-neutral-100 font-bold text-center
  @include text(28px, 38px)
  margin-bottom: 47px

  @screen md
    @include text(46px, 56px)
    margin-bottom: 57px

.text--large
  font-size: 32px
  line-height: 44px
  font-weight: 500

.h1
  font-size: 24px
  font-weight: 500
  line-height: 34px

.h2
  font-size: 18px
  font-weight: 500
  line-height: 25px

.h3
  font-size: 16px
  font-weight: 500
  line-height: 22px

.h4
  font-size: 14px
  font-style: normal
  font-weight: 500
  line-height: 20px

.p1
  font-weight: 500
  font-size: 14px
  line-height: 140%

.p2
  font-weight: 500
  font-size: 12px
  line-height: 140%


/* ant stylying */
.ant-tooltip-inner
  font-family: 'Work Sans', sans-serif

%select-font
  color: $primary-dark-blue
  font-weight: 500
  font-size: rem(14px)
  line-height: rem(19px)

// Hide arrows for number inputs
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0


/* Firefox */
input[type=number]
  -moz-appearance: textfield


// Ant select styles

.er-select
  min-width: rem(200px)

  &:not(.ant-select-customize-input)
    .ant-select-selector
      border: 1px solid #E0E0E0
      border-radius: 10px

  .ant-select-selection-item
    display: flex
    align-items: center
    @extend %select-font
    max-width: 300px

    @media (min-width: $breakpoint-md)
      max-width: 400px


  .ant-select-selection-placeholder
    position: relative
    bottom: 2px
    font-weight: normal
    font-size: 14px
    line-height: 20px
    color: $neutral-50

.er-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
    height: rem(46px) !important
    padding: 0 rem(16px)

.er-select.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.er-select.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.er-select.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder
  line-height: rem(48px)

// Assign styles globally cause it's tricky to apply styles from sass modules
// (scrollbar mounts outside select container)
.er-select__dropdown
  padding: 0
  .rc-virtual-list-scrollbar
    background-color: $grey-3 !important
    border-radius: 4px !important
    margin-right: 4px

  .rc-virtual-list-scrollbar-thumb
    border-radius: 4px !important
    background-color: $primary !important

  .ant-select-item
    padding: rem(16px)
    @extend %select-font
    //[class~=ant-select-single]:not([class~=ant-select-customize-input]) [class~=ant-select-selector]

// Ant Date picker
.ant-picker-suffix
  color: rgba(0, 0, 0, 0.8)

// Ant input
.ant-input, .ant-input-affix-wrapper
  border: 1px solid #E0E0E0
  border-radius: 10px

.ant-input-lg, .ant-input-affix-wrapper-lg
  padding: rem(10px) rem(11px)
  font-size: rem(14px)
  line-height: rem(20px)


// Ant Radio button
.ant-radio-checked
  &::after
    border: 1px solid $primary

  .ant-radio-inner
    border-color: $primary

    &::after
      background-color: $primary

// Switch button
.ant-switch-checked
  background-color: $primary

// Ant pagination
.ant-pagination-item
  padding: rem(8px) rem(10px)
  background-color: $grey-1
  border-radius: 6px
  color: $neutral-60
  border: none
  font-family: 'Circular Std', sans-serif
  font-size: rem(14px)
  line-height: rem(20px)
  width: fit-content
  min-height: 36px
  a
    color: $neutral-60
  &:hover
    background-color: $primary
    a
      color: white

  &-active
    color: white
    background-color: $primary
    a
      color: white
    &:hover
      a
        color: white

.ant-pagination-prev, .ant-pagination-next
    padding: rem(8px) (11px)
    color: $primary


