@import 'src/styles/variables'
@import '~sass-rem'

.container
  position: sticky
  top: 0
  bottom: 0
  display: flex
  flex-direction: column
  justify-content: space-between
  padding: rem(28px) 0 0 0
  width: 210px
  box-shadow: 1px 4px 30px rgba(129, 136, 172, 0.15)
  flex-shrink: 0
  z-index: 100

.containerFixed
  width: 210px
  display: flex
  flex-direction: column
  position: fixed

.container nav
  // margin-top: rem(30px)
  padding: 0 rem(18px) rem(20px)

.container ul
  display: flex
  flex-direction: column
  gap: rem(20px)

.logo
  margin-bottom: rem(10px)
  display: flex
  justify-content: center

.divider
  width: 100%
  height: 2px
  border-top: 1px solid #EDEDED

.navItem
  display: flex
  align-items: center
  gap: 10px
  padding: 0 rem(15px)
  height: rem(40px)
  cursor: pointer
  &:hover
    background-color: $primary
    color: white
    border-radius: 10px
  &--active
    background-color: $primary
    color: white
    border-radius: 10px

