@import 'src/styles/variables'
@import '~sass-rem'

.container
  position: fixed
  left: 0
  bottom: 0
  height: 90px
  width: 210px
  display: flex
  justify-content: flex-start
  gap: 8px
  padding: rem(36px) rem(6px) rem(36px) rem(8px)
  align-items: center
  border-top: 1px solid #EDEDED
  height: 90px
  background-color: white
  z-index: 110
  img
    height: 40px
    width: 40px
    border-radius: 10px

.user
  display: flex
  justify-content: space-between
  flex-grow: 1
  gap: rem(15px)
  align-items: center
  gap: rem(8px)
  cursor: pointer
  padding: rem(5px) rem(10px) rem(5px) 0

.avatarFallback
  height: 40px
  width: 40px
  border-radius: 10px
  background-color: rgba(144, 191, 69, 0.2)
  padding: rem(10px) rem(10px) rem(10px) rem(11px)
  color: $primary
  font-weight: 700
  text-transform: uppercase

.userName
  font-weight: 700
  font-size: rem(14px)
  line-height: rem(22px)

