@import 'src/styles/variables'
@import '~sass-rem'

.container
    height: fit-content
    flex-grow: 1
    padding: rem(30px) rem(20px)
    background-color: $grey-light

    h1
      font-weight: bold
      color: $neutral-100
      text-align: center
      font-size: rem(22px)
      line-height: rem(32px)

    @media (min-width: $breakpoint-md)
      padding: rem(44px) rem(37px)
      min-height: 100vh
      margin-bottom: 0

      h1
        font-size: rem(46px)
        line-height: rem(56px)

.videosContainer
  display: flex
  flex-direction: column
  justify-content: center
  margin-top: rem(50px)
  margin-bottom: rem(50px)

  @media (min-width: $breakpoint-md)
    flex-direction: row

.video
    flex-grow: 0
    border-radius: 12px
    width: 100%
    height: 100%

    @media (min-width: $breakpoint-md)
      height: 380px
      width: 490px
  
.contentImg    
    position: relative
    flex-grow: 0
    border-radius: 12px
    margin-right: 1rem
    margin-bottom: 1rem
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    padding: 0 rem(20px)
    height: 280px

    @media (min-width: $breakpoint-md)
      width: fit-content
  

    .playButton
      width: rem(88px)
      height: rem(88px)
      display: flex
      align-items: center
      justify-content: center
      border: 3px solid rgba(255, 255, 255, 0.7)
      border-radius: rem(100px)
      box-sizing: border-box
      z-index: 1
      position: absolute

.imgGradient
    height: 100%
    width: 100%
    position: absolute
    border-radius: 12px
    background: linear-gradient(357.89deg, rgba(0, 0, 0, 0.86) 4.78%, rgba(0, 0, 0, 0) 41.6%)


.testimonialName
    color:  white
    text-align: left
    z-index: 1
    margin-top: rem(38px)
    position: absolute
    left: 35px
    bottom: 45px

    @media (min-width: $breakpoint-md)
      bottom: 12px

    h2
        color:  white
        font-weight: bold
        font-size: rem(16px)
        line-height: rem(24px)   