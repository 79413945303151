@import '~sass-rem'
@import './variables'

@mixin text($font-size, $line-height: 1, $font-weight: inherit,  $color: inherit)
  font-size: rem($font-size)
  line-height: rem($line-height)
  @if $color != 'inherit'
    color: $color

  @if $font-weight != 'inherit'
    font-weight: $font-weight

