@import 'src/styles/variables'
@import '~sass-rem'

    
.container
    height: fit-content
    flex-grow: 1
    padding: rem(40px) 0
    display: flex
    flex-direction: column
    align-items: center
    font-weight: 400
    line-height: rem(26px)
    font-size: rem(16px)

    div
        margin-bottom: rem(30px)

    p
        width: 100%
    
    h1
        font-size: 2.8rem
        line-height: 3.5rem
        font-weight: 700
        text-align: center

    h2
        font-size: 2rem
        line-height: 2.4rem
        font-weight: 700
        text-align: center

    h3
        font-size: 1.4rem
        line-height: 2rem
        font-weight: 700
        text-align: center
    
    a 
        color: $primary
        text-decoration: none
        font-weight: 700
        &:hover
            color: $primary
            text-decoration: underline

    ol
        list-style: decimal
        padding-left: 2.5rem

    ul
        list-style: disc
        padding-left: 1.75rem

    @media (min-width: $breakpoint-md)
        padding: rem(80px) 0
    