@import 'src/styles/variables'
@import '~sass-rem'

.container
  height: fit-content
  width: 100%
  box-shadow: 0px 0px 40px rgba(230, 45, 71, 0.03)
  border-radius: 10px
  background-color: $neutral-100
  padding: rem(30px)
  position: relative
  text-align: center

  @media (min-width: $breakpoint-md)
    height: 350px
    padding: rem(40px)
    text-align: left

  h2
    color: $grey-1
    font-weight: bold
    font-size: rem(18px)
    line-height: rem(26px)

    @media (min-width: $breakpoint-md)
      font-size: rem(22px)
      line-height: rem(32px)

  h1
    color: $primary !important
    font-weight: bold !important
    font-size: 32px !important
    line-height: 38px !important
    margin-top: rem(12px) !important

    @media (min-width: $breakpoint-md)
      font-size: 32px !important
      line-height: 38px !important

  p
    color: $neutral-40
    width: fit-content
    margin-top: rem(13px)
    font-size: 16px

    @media (min-width: $breakpoint-md)
      font-size: 14px


.buttonPrimary
  background-color: $primary
  color: white
  padding: rem(12px)
  font-size: rem(16px)
  line-height: rem(24px)
  font-weight: 500
  border-radius: 8px
  outline: none
  display: block
  margin-top: rem(46px)
  width: 100%

  @media (min-width: $breakpoint-md)
    padding: rem(12px) rem(28px)
    width: max-content

  &:hover
    background-color: #afc88a
    color: $white
    border-color: inherit

.messageContainer
  position: absolute
  border-radius: 10px
  background-color: $neutral-10
  font-size: rem(10px)
  line-height: rem(14px)
  color: #2E2F31
  padding: rem(12px) rem(15px)
  box-shadow: 1px 0px 30px rgba(107, 19, 32, 0.2)
  opacity: 0

  @media (min-width: $breakpoint-md)
    font-size: rem(14px)
    line-height: rem(21px)

  img
    display: inline-block
    height: 18px
    width: 18px

    @media (min-width: $breakpoint-md)
      height: 30px
      width: 30px


// equilateral triangle
.messageArrow
  position: absolute
  --a: 1.7em
  border-bottom: calc(0.866 * var(--a)) solid $neutral-10
  border-left: calc(var(--a) / 2) solid transparent
  border-right: calc(var(--a) / 2) solid transparent
  border-top: 0
  height: 0
  width: 0
  right: 289px
  transform: rotate(73deg) scale(0.5)
  top: 23px

  @media (min-width: $breakpoint-md)
    transform: rotate(73deg) scale(1)
    top: 23px

@keyframes slide
  from
    top: 280px
    opacity: 0
  10%
    opacity: 1
  20%
    opacity: 1
  80%
    opacity: 1
  95%
    opacity: 0
  to
    top: 0px
    opacity: 0

@-webkit-keyframes slide
  from
    top: 280px
    opacity: 0
  10%
    opacity: 1
  20%
    opacity: 1
  80%
    opacity: 1
  95%
    opacity: 0
  to
    top: 0px
    opacity: 0

@keyframes slideMobile
  from
    top: 200px
    opacity: 0
  10%
    opacity: 1
  20%
    opacity: 1
  80%
    opacity: 1
  95%
    opacity: 0
  to
    top: 0px
    opacity: 0

@-webkit-keyframes slideMobile
  from
    top: 280px
    opacity: 0
  10%
    opacity: 1
  20%
    opacity: 1
  80%
    opacity: 1
  95%
    opacity: 0
  to
    top: 0px
    opacity: 0

.slide-animation
  animation: slide 3.5s linear infinite

.slide-animation--mobile
  animation: slideMobile 3.5s linear infinite