@import 'src/styles/variables'
@import '~sass-rem'


.container
    height: fit-content
    flex-grow: 1
    padding: rem(50px) rem(20px)
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    font-weight: 400
    line-height: rem(26px)
    font-size: rem(16px)
    width: 100%

    @media (min-width: $breakpoint-md)
        padding: rem(80px) rem(60px)

.contactFormContainer
    // width: rem(1060px)
    height: fit-content
    background: #FFFFFF
    box-shadow: 0px 4px 29px rgba(213, 222, 232, 0.55)
    border-radius: 12px
    display: flex
    z-index: 1
    flex-direction: column
    padding-top: rem(36px)
    padding-bottom: rem(70px)
    padding-left: rem(30px)
    padding-right: rem(30px)
    margin-bottom: rem(80px)

    @media (min-width: $breakpoint-md)
        padding-top: rem(58px)
        padding-left: rem(70px)
        padding-right: rem(70px)

    @media (min-width: $breakpoint-lg)
        flex-direction: row


.formSection

    h2
        font-weight: bold
        font-size: 22px
        line-height: 32px
        text-align: center
        color: #404040

    @media (min-width: $breakpoint-md)
        text-align: left

        h2
            text-align: left
            font-size: 32px
            line-height: 38px

.formFieldsBox
    margin-top: rem(48px)
    display: flex
    flex-direction: column

    @media (min-width: $breakpoint-md)
        flex-direction: row
        width: rem(496px)

.formGroup
    width: 100%
    display: flex
    flex-direction: column

    @media (min-width: $breakpoint-md)
        width: rem(496px)
        flex-direction: row
        justify-content: space-between
        align-content: space-between
        margin-bottom: rem(32px)

.formInput
    margin-bottom: rem(20px)

    input
        height: rem(46px) !important
        background: #ffffff !important

    @media (min-width: $breakpoint-md)
        margin-bottom: 0px
        width: rem(240px) !important

.formTextarea

    textarea
        height: rem(150px) !important
        background: #ffffff !important

.buttonPrimary
    background-color: $primary
    color: white
    padding: rem(16px) rem(36px)
    font-size: rem(16px)
    line-height: rem(24px)
    border-radius: 10px
    margin-top: rem(40px)
    outline: none
    display: block
    width: 100%
    &:hover
        background-color: #afc88a
        color: $white
        border-color: inherit

    @media (min-width: $breakpoint-md)
        width: auto
        margin-left: auto


.infoContainer

    @media (min-width: $breakpoint-md)
        margin-left: rem(82px)

.serviceHoursContainer
    margin-top: rem(100px)
    h4
        font-weight: bold
        font-size: rem(18px)
        line-height: rem(26px)
        margin-bottom: rem(20px)

    @media (min-width: $breakpoint-md)
        width: rem(292px)

    @media (min-width: $breakpoint-lg)
        margin-top: 0px

.serviceHour
    font-weight: normal
    color: $neutral-80
    font-size: rem(14px)
    line-height: rem(24px)
    margin-top: rem(10px)
    display: flex
    justify-content: space-between

    @media (min-width: $breakpoint-md)
        font-size: rem(16px)

.getIntouchBox
    margin-top: rem(60px)

    @media (min-width: $breakpoint-md)
        width: rem(292px)
        margin-top: rem(95px)
    h4
        font-weight: bold
        font-size: rem(18px)
        line-height: rem(26px)
        margin-bottom: rem(10px)

.contactInfo
    font-weight: normal
    color: $neutral-80
    font-size: rem(14px)
    line-height: rem(24px)
    margin-top: rem(10px)
    display: flex
    align-items: flex-start

    @media (min-width: $breakpoint-md)
        font-size: rem(16px)

.icon
    margin-right: rem(20px)

.contactDarkContainer
    background: $neutral-20
    position: absolute
    height: 60%
    width: 100%
    bottom: 0

.notification
  position: absolute
  right: 20px
  top: -50px
  z-index: 2
  
  @media (min-width: $breakpoint-md)
    right: 23%
    top: 80px