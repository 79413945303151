@import 'src/styles/variables'
@import '~sass-rem'

.container
  width: calc(100vw - 210px)
  position: fixed
  left: 210px
  bottom: 0
  height: 90px
  background-color: white
  border: 1px solid #EDEDED
  display: flex
  justify-content: flex-start
  align-items: center
  gap: rem(16px)
  padding: 0 rem(36px)
  transition: .5s ease-out
  transform: translateX(-100vw)

.open
  transform: translateX(0)

.button
  background-color: white
  outline: none
  font-weight: 400
  font-size: rem(14px)
  border: 1px solid #ccc
  border-radius: 8px
  padding: rem(10px) rem(18px)
  color: #404040
  svg
    height: rem(20px)
    transform: scale(1.4)
    color: #757575


.buttonLogout
  border: 1px solid $primary
  color: $primary
  &:hover
    border-color: inherit
    background-color: $primary
    color: white

.buttonClose
  margin-left: auto

.divider
  height: 46%
  width: 1px
  border-right: 1px solid #EDEDED
  margin: 0 10px
