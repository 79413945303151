@import 'src/styles/mixins'

.card
  @apply bg-grey-1 text-neutral-90
  width: 103px
  min-height: 73px
  border-radius: 12px
  padding: 12px 12px 12px

  @screen md
    width: 118px
    height: 80px
    padding: 14px 16px 18px

.title
  @apply text-neutral-90 text-center
  @include text(14px, 20px)
  @screen md
    @include text(16px, 24px)

.amount
  @apply font-bold text-center
  @apply text-neutral-90
  @include text(16px, 24px)
  @screen md
    @include text(18px, 26px)
